import { RESUME_FEATURES } from '~/app/shared/enums/resume-features.enum';
import { PermissionParam } from '~/app/shared/types/authorisation/permission-param.type';
import { BinaryOperator } from '~/app/shared/types/binary-operator.type';

export abstract class BasePermissionsService {
    public abstract hasPermissions(
        permissions: string[],
        permissionParams: PermissionParam[] | null,
        binaryOperator: BinaryOperator,
    ): boolean;

    public abstract hasPermissions(
        permissions: string[],
        permissionParams: PermissionParam[] | null,
    ): boolean;

    public abstract hasPermissions(
        permissions: string[],
    ): boolean;

    public abstract onLockedFeatureClick(context: RESUME_FEATURES | null, permissions: string[], extraInfoKey: string | null): void;

    public abstract onDispose(): void

    public abstract isNBSimulationHistoricalProcessedStrictlyInferiorToMaxAllowed(nbSimulationLaunched: number): boolean;

    public abstract isSimulationScenarioProcessMaxAllowed(nbSimulationLaunched: number): boolean;
}
