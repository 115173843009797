import {
    Injectable,
    NgZone,
} from '@angular/core';
import {
    Observable,
    OperatorFunction,
    Subject,
} from 'rxjs';
import { filter } from 'rxjs/operators';

import { BROADCAST_CHANNEL_NAME } from '~/app/core/constants/broadcast-channels.constants';

export type BroadcastMessage<T = any> = {
  type: string,
  payload: T,
}

function runInZone<T>(zone: NgZone): OperatorFunction<T, T> {
    return (source) => new Observable((observer) => {
        const onNext = (value: T) => zone.run(() => observer.next(value));
        const onError = (e: any) => zone.run(() => observer.error(e));
        const onComplete = () => zone.run(() => observer.complete());
        return source.subscribe(onNext, onError, onComplete);
    });
}

@Injectable({
    providedIn: 'root',
})
export class BroadcastChannelService {
    private broadcastChannel: BroadcastChannel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);

    private onMessage = new Subject<BroadcastMessage>();

    constructor(private ngZone: NgZone) {
        this.broadcastChannel.onmessage = (message) => this.onMessage.next(message.data);
    }

    publish<T = any>(type: string, payload: T): void {
        this.broadcastChannel.postMessage({
            type,
            payload,
        });
    }

    messagesOfType<T = any>(type: string): Observable<BroadcastMessage<T>> {
        return this.onMessage.pipe(
            runInZone(this.ngZone),
            filter((message) => message.type === type),
        );
    }
}
